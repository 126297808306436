import * as React from "react";
import { useState, useCallback } from "react";
import {
  Button,
  Badge,
  List,
  Backdrop,
  CircularProgress,
  ListItem,
  ListItemText,
  useTheme,
  useMediaQuery,
  IconButton,
  Container,
  SwipeableDrawer,
  Box,
  ListItemIcon,
  Divider,
  Avatar,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import NotificationsNoneIcon from "@material-ui/icons/NotificationsNone";
import MenuIcon from "@material-ui/icons/Menu";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import LogoutIcon from "@material-ui/icons/ExitToApp";
import CalendarMonthIcon from "@material-ui/icons/DateRange";
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./Header.scss";
import { Auth } from "aws-amplify";
import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn, awsSignOut } from "../../redux/auth/authSlice";
import SearchIcon from "@material-ui/icons/Search";
import BookmarkIcon from "@material-ui/icons/Bookmark";
import config from "../../aws-exports";
import moment from "moment";
import Tippy from "@tippyjs/react";
import Cookies from "js-cookie";
import Swal from "sweetalert2";
import Skeleton from "@mui/material/Skeleton";
import { AccountCircle } from "@material-ui/icons";
import Favorite from "@material-ui/icons/Favorite";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { API } from "aws-amplify";

import {
  getNotifications,
  getUserDetails,
  postNotificationStatusWeb,
  getActiveUsers,
} from "../../api/api";
import LocalGroceryStoreIcon from "@mui/icons-material/LocalGroceryStore";
import LanguageSelect from "../LanguageSelect/LanguageSelect";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ActiveUsers from "../ActiveUsers/ActiveUsers";
 
import axios from "axios";
import Tooltip from '@mui/material/Tooltip';
 
import { debounce } from "lodash";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
 
const defaultTippyProps = {
  trigger: "mouseenter click",
  arrow: false,
  interactive: true,
  interactiveBorder: 10,
  placement: "bottom",
  delay: [0, 0],
  maxWidth: 500,
  appendTo: document.body,
  sticky: true,
  theme: "light",
  distance: 24,
  inertia: true,
};
function Header({ setTheme, theme }) {
  
  let userDetails = useSelector(authData);
  const useStyles = makeStyles((themeStyle) => ({
    root: {
      display: "flex",
      "& > *": {
        margin: themeStyle.spacing(1),
      },
    },
    mainTippy: {
      backgroundColor:
        userDetails.themeDark === "dark"
          ? "#3F434D !important"
          : themeStyle.palette.background.paper,
      boxShadow: userDetails.themeDark === "dark" ? "none !important" : "auto",
      "& > div > div > div > ul > a": {
        color:
          userDetails.themeDark === "dark"
            ? "white !important"
            : "#3F434D !important",
      },
      "& > div > div > div > nav > a": {
        color:
          userDetails.themeDark === "dark"
            ? "white !important"
            : "#3F434D !important",
      },
      "& > div > div > div > nav > div > div": {
        color:
          userDetails.themeDark === "dark"
            ? "white !important"
            : "#3F434D !important",
      },
    },
    root1: {
      width: "300px",
      maxWidth: 360,
      marginTop: "5px",
      maxHeight: "88vh",
      overflow: "auto",
      overflowX: "hidden",
      overflowWrap: "break-word",
    },
    root121: {
      maxHeight: "calc(100vh - (100vh/2))",
      overflowX: "hidden",
      overflowY: "auto",
      "@media (min-width: 426px)": {
        width: "300px",
        maxWidth: 360,
      },
    },
    searchOnMobile: {
      width: "100%",
      maxHeight: "calc(100vh - (100vh/2))",
      overflowX: "hidden",
      overflowY: "auto",
    },
    root123: {
      width: "300px",
      maxWidth: 360,
    },
    root12: {
      width: "fit-content",
      maxWidth: 360,
      fontSize: "1.5rem",
      marginTop: "-5px",
    },
    accountButton: {
      position: "relative",
      minWidth: "auto",
      "@media (max-width: 500px)": {
        padding: 0,
      },
      "&:focus": {
        backgroundColor: "black",
        borderRadius: "2rem",
      },
    },
    small: {
      width: themeStyle.spacing(3.8),
      height: themeStyle.spacing(3.8),
      "@media (max-width: 500px)": {
        width: "2rem",
      },
    },
    medium: {
      width: themeStyle.spacing(4),
      height: themeStyle.spacing(4),
    },
    large: {
      width: themeStyle.spacing(7),
      height: themeStyle.spacing(7),
    },
    paper: {
      marginRight: themeStyle.spacing(2),
    },
    badge: {
      "& .MuiBadge-badge": {
        background: "#fff",
        fontSize: "1rem",
        fontWeight: "bold",
        "&:active": {
          color: "#fff",
        },
      },
    },
    headerIcon: {
      color: "white",
      fontSize: "3rem!important",
      cursor: "pointer",
      "@media (max-width: 500px)": {
        fontSize: "2.5rem!important",
      },
      "&:focus": {
        backgroundColor: "black",
        borderRadius: 0,
      },
    },
    headerIconSearch: {
      "&:focus": {
        backgroundColor: "transparent",
        border: "2px solid white",
        borderRadius: 0,
      },
    },
    body1: {
      "& .MuiTypography-body1": {
        fontSize: "1.5rem",
        lineHeight: "1.3",
        fontFamily: `myfontregular`,
      },
    },
    backdrop: {
      zIndex: themeStyle.zIndex.drawer + 1,
      color: "#fff",
    },
    exploreContainer: {
      height: "inherit",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "@media (max-width: 770px)": {
        display: "none",
      },
    },
    responsiveMenu: {
      "@media (max-width: 770px)": {
        display: "none",
      },
    },
    menuIconResponsive: {
      "@media (min-width: 770px)": {
        display: "none",
      },
    },
    tippyMobileSearch: {
      backgroundColor:
        userDetails.themeDark === "dark"
          ? "#3F434D !important"
          : themeStyle.palette.background.paper,
      marginTop: "1.7rem",
    },
    MenuIconList: {
      textDecoration: "none",
      "& li > button > span > div > svg": {
        width: "2.5rem",
        height: "2.5rem",
        color: `var(--color-primary)`,
        fill: `var(--color-primary)`,
      },
      "& li > button > span > div > span": {
        fontSize: "1.3rem",
        color: userDetails.themeDark === "dark" ? "white" : "black",
        fontFamily: `myfontregular`,
        fontWeight: "700",
      },
      "& div > button > span > div > svg": {
        width: "2.5rem",
        height: "2.5rem",
        color: `var(--color-primary)`,
      },
      "& div > button > span > div > span": {
        fontSize: "1.3rem",
        color: userDetails.themeDark === "dark" ? "white" : "black",
        fontFamily: `myfontregular`,
        fontWeight: "700",
      },
    },
    menuLogo: {
      width: "60%",
      padding: "0.3rem 0rem 0rem",
      margin: "auto",
    },
    menuDrawer: {
      "& > div:nth-child(3)": {
        backgroundColor:
          userDetails.themeDark === "dark"
            ? "black !important"
            : themeStyle.palette.background.paper,
        color: userDetails.themeDark === "dark" ? "white !important" : "black",
      },
    },
  }));
  const location = useLocation();
  const queryClient = useQueryClient();
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const [headerLoader, setHeaderLoader] = useState(false);
  const [drawerState, setDrawerState] = React.useState(false);
  const [items, setItems] = useState([]);
  const [actCount, setActCount] = useState(0);
  const themeTheme = useTheme();
  const matches = useMediaQuery(themeTheme.breakpoints.down("1075"));
  const setMode = (mode) => {
    localStorage.setItem("theme", mode);
    setTheme(mode);
    let sdata = { ...userDetails };
    sdata.themeDark = mode;
    dispatch(awsSignIn(sdata));
  };
  const toggleTheme = () => {
    theme === "light" ? setMode("dark") : setMode("light");
  };
  const { data: user, isLoading: spin } = useQuery({
    queryKey: ["user"],
    queryFn: () => getUserDetails(userDetails),
  });
  const { data: usersCount, isLoading: spins } = useQuery({
    queryKey: ["users"],
    queryFn: () => getActiveUsers(userDetails, true, true),
  });
  const { data: notify } = useQuery({
    queryKey: ["notify"],
    queryFn: () => getNotifications(userDetails),
  });
  const { error, mutate, isError } = useMutation({
    mutationFn: () => postNotificationStatusWeb(userDetails, notify),
    onSuccess: async (updateNotify) => {
      queryClient.setQueriesData(["notify"], updateNotify);
      const notifications = await getNotifications(userDetails);
      queryClient.setQueryData(["notify"], notifications);
    },
  });
  async function notificationStatus(index) {
    mutate({
      ...notify,
      unReadNotify: [],
      notRead: 0,
    });
  }
  const signingOut = () => {
    Auth.signOut();
    dispatch(awsSignOut());
    setHeaderLoader(true);
    getUserData();
  };
  const [search, setSearch] = useState("");
  const setInactive = async () => {
    const success = await sendBeaconRequest("/updateUserActive", "false");
    if (success) {
      console.log("Beacon request sent successfully.");
    } else {
      console.log("Beacon request failed.");
    }
  };
  React.useEffect(() => {
    if (usersCount) {
      setActCount(usersCount[0].count);
    }
  }, []);

  async function searchData(searchString) {
    try {
      const requestBody = {
        searchTerm: searchString,
        tenant: userDetails?.uData?.oid,
        schema: config.schema,
        urid: userDetails?.uData?.ur_id,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/searchTopics?json=${encodeURIComponent(
        base64EncodedString
      )}`;

      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken,
          },
        }
      );

      const uniqueId = new Set();
      const uniqueSession = response.search.filter((el) => {
        const isDuplicate = uniqueId.has(el.sid);
        uniqueId.add(el.sid);
        if (!isDuplicate) {
          return true;
        }
        return false;
      });
      let apiData = uniqueSession;
      let items = [];

      apiData.forEach((val) => {
        let obj = {};

        // Handling based on itype
        switch (val.itype) {
          case 1: // Courses
            obj.id = val.sid;
            obj.name = val.tn || "";
            break;

          case 2: // Objects
            obj.id = val.sid;
            obj.name = val.tn || "";
            break;

          case 3: // Learning
            obj.id = val.sid;
            obj.name = val.tn || "";
            break;

          case 4: // Category
            obj.id = val.ct_id;
            obj.name = val.ct_name || "";
            break;

          default:
            break;
        }

        // Push to items array if both id and name are present
        if (obj.id && obj.name) {
          items.push(obj);
        }
      });
      setItems(items);
    } catch (err) {
      console.error(err);
    }
  }

  // Debounced API search call
  const debouncedSearch = useCallback(
    debounce((searchString) => {
      searchData(searchString);
    }, 300), // 300 ms debounce time
    []
  );

  const handleOnSearch = (string, results) => {
    // Use the debounced function instead of directly calling the API

    if (string.length % 2 === 0 && string.length > 0) {
      debouncedSearch(string);
    }
 
  };

  const handleOnSelect = (item) => {
    handleSearchBread(item.name);
  };

  const handleOnFocus = () => {};

  const formatResult = (item) => {
    return (
      <span style={{ display: "block", textAlign: "left" }}>{item.name}</span>
    );
  };

  function sendBeaconRequest(url, data) {
 
    const jsonData = JSON.stringify({
      oid: config.aws_org_id,
      schema: config.schema,
      ur_id: userDetails?.uData?.ur_id,
 
      bool: data,
    });
    // Send the Beacon request
 
    const success = navigator.sendBeacon(
      `${config.aws_cloud_logic_custom_endpoint}${url}`,
      jsonData
    );
    return success;
  }
  function formatUrl(url) {
    var httpString = "http://",
      httpsString = "https://";
    if (
      url.substr(0, httpString.length) !== httpString &&
      url.substr(0, httpsString.length) !== httpsString
    )
      url = httpString + url;
    return url;
  }
  const openNotification = (msg, index) => {
    if (msg.type === 1) {
      history.push(`/course?id=${msg.tid}`);
    } else if (msg.link) {
      window.open(formatUrl(msg.link), "_blank");
    } else {
      Swal.fire({
        title: msg.title,
        text: msg.msg,
        width: "400px",
        showConfirmButton: true,
        confirmButtonText: "OK",
        customClass: {
          confirmButton: "confirmButton",
          title: "swalTitle",
        },
        allowOutsideClick: false,
        allowEscapeKey: false,
        buttonsStyling: false,
      }).then(async (result) => {
        await notificationStatus(index);
      });
    }
  };
  async function getUserData() {
    
    Cookies.remove("CloudFront-Policy");
    Cookies.remove("CloudFront-Key-Pair-Id");
    Cookies.remove("CloudFront-Signature");
    Cookies.remove("refresh_token");
    Cookies.remove("id_token");
    await setInactive();
    var padmin = localStorage.getItem("persist:adminRoot");
    localStorage.clear();
    sessionStorage.clear();
    window.onbeforeunload = () => {
      localStorage.clear();
    };
    localStorage.setItem("accessToken", userDetails.beforeAuth);
    padmin !== null && localStorage.setItem("persist:adminRoot", padmin);
    if (config.aws_org_id === "ICICI") {  
      window.location.href = config.aws_cloudfront_lurl;
      
    } 
    if (config.aws_org_id !== "EXCELEARN" && config.aws_org_id !== "NSECORP") {
      history.push({ pathname: "/" });
    } else if (config.aws_org_id === "NSECORP") {
      window.location.href = config.aws_cloudfront_lurl;
    } 
    else {
      Cookies.remove("access_token");
      const clientId = config.aws_user_pools_web_client_id;
    const logoutRedirectUri = encodeURIComponent(`${window.location.origin}`);
    const idpLogoutUrl = `https://excelearn.auth.ap-south-1.amazoncognito.com/logout?client_id=${clientId}&logout_uri=${logoutRedirectUri}`;
    // Redirect to the IdP logout URL
    window.location.href = idpLogoutUrl;
    }
  }
  const handleSearchBread = async (search) => {
    let breadcrumb = { ...userDetails };
    const searchTopic = search.trim();
    if (searchTopic.length !== 0) {
      breadcrumb.location = {
        from: "Search",
        topicName: searchTopic,
        topicId: "search",
      };
      delete breadcrumb.filter;
      dispatch(awsSignIn(breadcrumb));
      setSearch("");
    }
    history.push(`/explore/${encodeURIComponent(search.trim())}/search`);
  };
  const toggleDrawer = (open, bool) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerState(bool);
  };
  const DrawerList = (anchor) => (
    <Box
      sx={{ width: anchor === "top" || anchor === "bottom" ? "auto" : 250 }}
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <nav aria-label="main mailbox folders">
        <List>
          <ListItem disablePadding style={{ padding: 0, height: "5.5rem" }}>
            <img
              src={
                userDetails.themeDark === "dark"
                  ? `https://${
                      config.DOMAIN
                    }/${config.aws_org_id.toLowerCase()}-resources/images/org-images/logo-light.png`
                  : `https://${
                      config.DOMAIN
                    }/${config.aws_org_id.toLowerCase()}-resources/images/org-images/${
                      config.aws_org_id
                    }.png`
              }
              alt="app logo"
              title="app logo"
              className={classes.menuLogo}
              onClick={() => history.push("/home")}
            />
          </ListItem>
          <Divider style={{ margin: "0.8rem 0rem" }} />
          <Link to={`/home`} className={classes.MenuIconList}>
            <ListItem
              disablePadding
              className={
                location.pathname.split("/").indexOf("home") > -1
                  ? "side-nav__item side-nav__item--active align"
                  : "side-nav__item align"
              }
            >
              <Button>
                <ListItemIcon>
                  <DashboardIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${userDetails?.languagejson?.header?.Dashboard ||
                    "Dashboard"}`}
                  style={{ fontFamily: `myfontregular` }}
                />
              </Button>
            </ListItem>
          </Link>
          <Link to={`/viewAllTopics`} className={classes.MenuIconList}>
            <ListItem
              disablePadding
              className={
                location.pathname.split("/").indexOf("viewAllTopics") > -1
                  ? "side-nav__item side-nav__item--active align"
                  : "side-nav__item align"
              }
            >
              <Button>
                <ListItemIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30px"
                    height="30px"
                    viewBox="0 0 36 36"
                  >
                    <defs>
                      <linearGradient
                        id="linear-gradient"
                        x1="0.5"
                        x2="0.657"
                        y2="1"
                        gradientUnits="objectBoundingBox"
                      >
                        <stop offset="0" stop-color="#31a0df" />
                        <stop offset="1" stop-color="#037cc2" />
                      </linearGradient>
                      <clipPath id="clip-path">
                        <rect
                          id="Rectangle_1824"
                          data-name="Rectangle 1824"
                          width="36"
                          height="36"
                        />
                      </clipPath>
                    </defs>
                    <g id="explore" clip-path="url(#clip-path)">
                      <g
                        id="explore-2"
                        data-name="explore"
                        clip-path="url(#clip-path)"
                      >
                        <g id="Multimedia" transform="translate(2 171)">
                          <path
                            id="Path_123"
                            data-name="Path 123"
                            d="M7.077,8.157H28.933a4.952,4.952,0,0,1,1.175.149A3.286,3.286,0,0,0,26.983,6H8.918A3.286,3.286,0,0,0,5.785,8.33a4.97,4.97,0,0,1,1.291-.173ZM4.9,11.8H31a4.319,4.319,0,0,1,1.254.186,3.458,3.458,0,0,0-3.322-2.379H7.077a3.516,3.516,0,0,0-3.1,1.849,3.467,3.467,0,0,0-.215.5A4.319,4.319,0,0,1,4.9,11.8ZM31,13.25H4.9A2.9,2.9,0,0,0,2,16.15V29.926a2.9,2.9,0,0,0,2.9,2.9H31a2.9,2.9,0,0,0,2.9-2.9V16.15a2.9,2.9,0,0,0-2.9-2.9ZM22.964,24.922l-6.525,3.767A2.175,2.175,0,0,1,13.176,26.8V19.271a2.175,2.175,0,0,1,3.263-1.884l6.525,3.767a2.175,2.175,0,0,1,0,3.767Z"
                            transform="translate(-2 -172)"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </ListItemIcon>
                <ListItemText
                  primary={`${userDetails?.languagejson?.header?.Explore ||
                    "Explore"}`}
                  style={{ fontFamily: `myfontregular` }}
                />
              </Button>
            </ListItem>
          </Link>
          <Link to={`/calender`} className={classes.MenuIconList}>
            <ListItem
              disablePadding
              className={
                location.pathname.split("/").indexOf("calender") > -1
                  ? "side-nav__item side-nav__item--active align"
                  : "side-nav__item align"
              }
            >
              <Button>
                <ListItemIcon>
                  <CalendarMonthIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${userDetails?.languagejson?.header?.Calendar ||
                    "Calendar"}`}
                />
              </Button>
            </ListItem>
          </Link>
          {config.aws_org_id !== "NSECORP" && (
            <Link to={`/leaderboard`} className={classes.MenuIconList}>
              <ListItem
                disablePadding
                className={
                  location.pathname.split("/").indexOf("leaderboard") > -1
                    ? "side-nav__item side-nav__item--active align"
                    : "side-nav__item align"
                }
              >
                <Button>
                  <ListItemIcon>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="30px"
                      height="30px"
                      viewBox="0 0 36 36"
                    >
                      <defs>
                        <linearGradient
                          id="linear-gradient"
                          x1="0.59"
                          y1="1"
                          x2="0.5"
                          y2="0.128"
                          gradientUnits="objectBoundingBox"
                        >
                          <stop offset="0" stop-color="#31a0df" />
                          <stop offset="1" stop-color="#037cc2" />
                        </linearGradient>
                        <clipPath id="clip-path">
                          <rect
                            id="Rectangle_1825"
                            data-name="Rectangle 1825"
                            width="36"
                            height="36"
                          />
                        </clipPath>
                      </defs>
                      <g id="leaderboard" clip-path="url(#clip-path)">
                        <g
                          id="leaderboard-2"
                          data-name="leaderboard"
                          clip-path="url(#clip-path)"
                        >
                          <g
                            id="Group_1618"
                            data-name="Group 1618"
                            transform="translate(-0.708 1.073)"
                          >
                            <path
                              id="Path_1222"
                              data-name="Path 1222"
                              d="M32.89,30.364v1.57H5.327v-1.57a.885.885,0,0,1,.885-.884H8.7V20.815h6.119V29.48h1.072V12.88h6.275v16.6H23.24V24.835h6.129V29.48h2.637a.885.885,0,0,1,.884.884ZM16.7,7.86l-.4,2.084a.544.544,0,0,0,.2.531.535.535,0,0,0,.563.053l1.9-.954,1.86,1.029a.541.541,0,0,0,.787-.552l-.316-2.1L22.843,6.5a.537.537,0,0,0,.15-.547.558.558,0,0,0-.429-.375L20.469,5.23l-.9-1.918A.545.545,0,0,0,19.092,3a.535.535,0,0,0-.488.289L17.63,5.175l-2.111.263a.55.55,0,0,0-.439.359.539.539,0,0,0,.128.552Z"
                            />
                          </g>
                        </g>
                      </g>
                    </svg>
                  </ListItemIcon>
                  <ListItemText
                    primary={`${userDetails?.languagejson?.header
                      ?.Leaderboard || "Leaderboard"}`}
                  />
                </Button>
              </ListItem>
            </Link>
          )}
          {config.aws_org_id !== "NSECORP" && (
            <Link to={`/myCoins`} className={classes.MenuIconList}>
              <ListItem
                disablePadding
                className={
                  location.pathname.split("/").indexOf("myCoins") > -1
                    ? "side-nav__item side-nav__item--active align"
                    : "side-nav__item align"
                }
              >
                <Button>
                  <ListItemIcon>
                    <LocalGroceryStoreIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${userDetails?.languagejson?.header?.My_Coins ||
                      "My Coins"}`}
                  />
                </Button>
              </ListItem>
            </Link>
          )}
          {config.header.bookmark && (
            <Link to={`/bookmarks`} className={classes.MenuIconList}>
              <ListItem
                disablePadding
                className={
                  location.pathname.split("/").indexOf("bookmarks") > -1
                    ? "side-nav__item side-nav__item--active align"
                    : "side-nav__item align"
                }
              >
                <Button>
                  <ListItemIcon>
                    <BookmarkIcon />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${userDetails?.languagejson?.header?.Bookmarks ||
                      "Bookmarks"}`}
                  />
                </Button>
              </ListItem>
            </Link>
          )}
          {config.header.wishlist && (
            <Link to={`/wishlist`} className={classes.MenuIconList}>
              <ListItem
                disablePadding
                className={
                  location.pathname.split("/").indexOf("wishlist") > -1
                    ? "side-nav__item side-nav__item--active align"
                    : "side-nav__item align"
                }
              >
                <Button>
                  <ListItemIcon>
                    <Favorite />
                  </ListItemIcon>
                  <ListItemText
                    primary={`${userDetails?.languagejson?.header?.Wishlist ||
                      "Wishlist"}`}
                  />
                </Button>
              </ListItem>
            </Link>
          )}
          <Link to={`/contactus`} className={classes.MenuIconList}>
            <ListItem
              disablePadding
              className={
                location.pathname.split("/").indexOf("contactus") > -1
                  ? "side-nav__item side-nav__item--active align"
                  : "side-nav__item align"
              }
            >
              <Button>
                <ListItemIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30px"
                    height="30px"
                    viewBox="0 0 36 36"
                  >
                    <defs>
                      <linearGradient
                        id="linear-gradient"
                        x1="0.29"
                        y1="1.561"
                        x2="0.843"
                        y2="0.127"
                        gradientUnits="objectBoundingBox"
                      >
                        <stop offset="0" stop-color="#31a0df" />
                        <stop offset="1" stop-color="#037cc2" />
                      </linearGradient>
                      <clipPath id="clip-path">
                        <rect
                          id="Rectangle_1820"
                          data-name="Rectangle 1820"
                          width="36"
                          height="36"
                        />
                      </clipPath>
                    </defs>
                    <g id="Contact_us" clip-path="url(#clip-path)">
                      <g
                        id="Contact_us-2"
                        data-name="Contact_us"
                        clip-path="url(#clip-path)"
                      >
                        <g
                          id="Group_1845"
                          data-name="Group 1845"
                          transform="translate(-3.502 -4.203)"
                        >
                          <path
                            id="Path_1229"
                            data-name="Path 1229"
                            d="M46.408,13.541A9.4,9.4,0,0,0,27.79,15.415v9.4h9.4a9.492,9.492,0,0,0,9.219-11.273Zm-2.614,1.892a1.557,1.557,0,1,1-1.556-1.5,1.557,1.557,0,0,1,1.556,1.5Zm-13.2,0a1.556,1.556,0,1,1,1.555,1.558,1.556,1.556,0,0,1-1.555-1.558Zm5.042-.019a1.556,1.556,0,1,1,1.555,1.558,1.556,1.556,0,0,1-1.555-1.558Z"
                            transform="translate(-8.228)"
                          />
                          <path
                            id="Path_1230"
                            data-name="Path 1230"
                            d="M24.984,42.867a3.73,3.73,0,0,0,3.455-.977,18.76,18.76,0,0,0,2-2.322,1.887,1.887,0,0,0-.635-2.751l-3.623-1.911a3.742,3.742,0,0,0-3.766.162l-2.683,1.724a21.4,21.4,0,0,1-7.569-7.569l1.724-2.683a3.742,3.742,0,0,0,.162-3.766l-1.911-3.623a1.882,1.882,0,0,0-2.751-.635,18.759,18.759,0,0,0-2.322,2,3.73,3.73,0,0,0-.977,3.455,25.2,25.2,0,0,0,18.9,18.9Z"
                            transform="translate(0 -4.583)"
                          />
                        </g>
                      </g>
                    </g>
                  </svg>
                </ListItemIcon>
                <ListItemText
                  primary={`${userDetails?.languagejson?.header?.contact_us ||
                    "Contact Us"}`}
                />
              </Button>
            </ListItem>
          </Link>
          <Link to={`/profile`} className={classes.MenuIconList}>
            <ListItem
              disablePadding
              className={
                location.pathname.split("/").indexOf("profile") > -1
                  ? "side-nav__item side-nav__item--active align"
                  : "side-nav__item align"
              }
            >
              <Button>
                <ListItemIcon>
                  <AccountCircle />
                </ListItemIcon>
                <ListItemText
                  primary={`${userDetails?.languagejson?.header?.Profile ||
                    "Profile"}`}
                />
              </Button>
            </ListItem>
          </Link>
          <Link to="#" className={classes.MenuIconList}>
            <ListItem
              disablePadding
              button
              onClick={() => {
                signingOut();
              }}
            >
              <Button>
                <ListItemIcon>
                  <LogoutIcon />
                </ListItemIcon>
                <ListItemText
                  primary={`${userDetails?.languagejson?.header?.Logout ||
                    "Logout"}`}
                />
              </Button>
            </ListItem>
          </Link>
        </List>
      </nav>
      <Divider />
    </Box>
  );
  if (isError)
    return (
      <small>
        <pre>{JSON.stringify(error, null, 2)}</pre>
      </small>
    );
  return (
    <>
      {headerLoader ? (
        <Backdrop className={classes.backdrop} open={true}>
          <CircularProgress color="inherit" />
        </Backdrop>
      ) : null}
      <header className="headerbackcolor" aria-label="Header">
        <Container maxWidth="xl">
          <div className="header">
            <div className={classes.menuIconResponsive}>
              <MenuIcon
                className={classes.headerIcon}
                onClick={toggleDrawer("left", true)}
              />
              <SwipeableDrawer
                className={classes.menuDrawer}
                anchor={"left"}
                open={drawerState}
                onClose={toggleDrawer("left", false)}
                onOpen={toggleDrawer("left", true)}
              >
                {DrawerList("left")}
              </SwipeableDrawer>
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Link
                to="/home"
                style={{ display: "flex", gap: 0.5, alignItems: "center" }}
              >
                <img
                  src={`https://${
                    config.DOMAIN
                  }/${config.aws_org_id.toLowerCase()}-resources/images/org-images/logo-light.png`}
                  alt="app logo"
                  className="logo"
                />
                {config.aws_org_id?.toLowerCase() === "icici" && (
                  <img
                    src={`https://${
                      config.DOMAIN
                    }/${config.aws_org_id?.toLowerCase()}-resources/images/org-images/App-icon.png`}
                    alt="logo"
                    height="42.5px"
                  />
                )}
              </Link>
            </div>
 
            {location.pathname.split("/").indexOf("social-learning") === -1 && (
              <div className={`search ${matches && "hide"}`}>
                <div style={{ width: 300 }}>
                  {/* Wrap ReactSearchAutocomplete in Tippy */}
                  <ReactSearchAutocomplete
                    items={items}
                    onSearch={handleOnSearch}
                    onSelect={handleOnSelect}
                    onFocus={handleOnFocus}
                    autoFocus
                    formatResult={formatResult} // Format each search result
                    placeholder="Search..."
                    showIcon={false}
                  />
                </div>
                <button
                  className="search__button"
                  title="Search"
                  onClick={() => {
                    if (search != "") {
                      handleSearchBread(search);
                    }
 
                  }}
                >
                  <SearchIcon
                    style={{
                      fontSize: "2.6rem",
                      marginTop: "0.5rem",
                      color: "#0077C6",
                    }}
                  />
                </button>
              </div>
            )}
            <nav className="user-nav">
              {matches && (
                <div>
                  <Tippy
                    className={classes.tippyMobileSearch}
                    content={
                      <div
                        style={{
                          padding: "10px 0px",
                          width: 300,
                        }}
                      >
                        <ReactSearchAutocomplete
                          items={items}
                          onSearch={handleOnSearch}
                          onSelect={handleOnSelect}
                          onFocus={handleOnFocus}
                          autoFocus
                          formatResult={formatResult} // Format each search result
                          placeholder="Search..."
                          showIcon={false}
                        />
                      </div>
                    }
                    {...defaultTippyProps}
                  >
                    <IconButton
                      title="Search"
                      type="button"
                      aria-label="Search for course"
                      className={classes.headerIconSearch}
                    >
                      <SearchIcon className={classes.headerIcon} />
                    </IconButton>
                  </Tippy>
                </div>
              )}
 
              {spins ? (
                <></>
              ) : (
                config.activeUsers && (
                  <ActiveUsers activeCount={usersCount[0]?.count} />
                )
              )}
              <LanguageSelect />
 
              <div className={classes.responsiveMenu}>
                {userDetails.themeDark === "dark" && (
                  <Tooltip
                  title="Theme"
                  arrow
                  placement="top"
                  enterDelay={500}
                  leaveDelay={200}
                  disableInteractive
                  PopperProps={{
                    sx: {
                      '& .MuiTooltip-tooltip': {
                        backgroundColor: 'black', // Set the background color here
                        color: 'white', // Set the text color to ensure readability
                        fontSize:'0.9rem'
                      },
                      '& .MuiTooltip-arrow': {
                        color: 'black', // Match the arrow color with the tooltip background
                      },
                    },
                  }}
                >
                  <DarkModeOutlinedIcon
                    className={classes.headerIcon}
                    onClick={toggleTheme}
                  /></Tooltip>
                )}
                {userDetails.themeDark === "light" && (
                   <Tooltip
                   title="Theme"
                   arrow
                   placement="top"
                   enterDelay={500}
                   leaveDelay={200}
                   disableInteractive
                   PopperProps={{
                     sx: {
                       '& .MuiTooltip-tooltip': {
                         backgroundColor: 'black', // Set the background color here
                         color: 'white', // Set the text color to ensure readability
                         fontSize:'0.9rem'
                       },
                       '& .MuiTooltip-arrow': {
                         color: 'black', // Match the arrow color with the tooltip background
                       },
                     },
                   }}
                 >
                  <WbSunnyOutlinedIcon
                    className={classes.headerIcon}
                    onClick={toggleTheme}
                  />
                   </Tooltip>
                )}
              </div>
              {config.header.notification && (
                <div>
                  <Tippy
                    className={classes.mainTippy}
                    content={
                      <div
                        className={
                          notify?.notification === false ||
                          notify?.notification?.length === 0
                            ? classes.root123
                            : classes.root121
                        }
                        style={{ padding: "10px" }}
                      >
                        <List
                          component="nav"
                          aria-label="main mailbox folders"
                          className={classes.body1}
                        >
                          {notify?.notification === false ||
                          notify?.notification.length === 0 ? (
                            <div style={{ fontFamily: `myfontregular` }}>
                              No Notification
                            </div>
                          ) : (
                            notify?.notification?.map((notific, indx) => {
                              return (
                                <div key={indx}>
                                  <div
                                    onClick={() =>
                                      openNotification(notific, indx)
                                    }
                                    style={{ cursor: "pointer" }}
                                  >
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "space-between",
                                        paddingBottom: "10px",
                                        paddingTop: "10px",
                                        fontFamily: `myfontregular`,
                                      }}
                                    >
                                      <div style={{ fontSize: "15px" }}>
                                        {notific.title}
                                      </div>
                                      <div
                                        style={{
                                          fontSize: "12px",
                                          color: "rgb(119, 119, 119)",
                                        }}
                                      >
                                        {notific.time !== undefined &&
                                          notific.time !== null &&
                                          moment(parseInt(notific.time)).format(
                                            "DD MMM YYYY"
                                          )}
                                      </div>
                                    </div>
                                    <div style={{ fontSize: "14px" }}>
                                      {notific.msg}
                                    </div>
                                  </div>
                                  <hr
                                    style={{
                                      backgroundColor: "gray",
                                      marginTop: "6px",
                                    }}
                                  />
                                </div>
                              );
                            })
                          )}
                        </List>
                      </div>
                    }
                    {...defaultTippyProps}
                  >
                    <div
                      style={{
                        height: "inherit",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <Link
                        to="#"
                        title="Number of Notifications"
                        className={classes.badge}
                      >
                        <Badge
                          badgeContent={
                            notify?.notRead === undefined ||
                            notify?.notRead === 0
                              ? 0
                              : notify?.notRead
                          }
                          max={9}
                        >
                          <NotificationsNoneIcon
                            className={classes.headerIcon}
                          />
                        </Badge>
                      </Link>
                    </div>
                  </Tippy>
                </div>
              )}
              <div className={classes.responsiveMenu}>
                <Tippy
                  className={classes.mainTippy}
                  content={
                    <div className={classes.root12}>
                      <List style={{ fontSize: "14px" }} role="navigation">
                        <Link
                          to={`/profile`}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <ListItem>{`${userDetails?.languagejson?.header
                            ?.Profile || "Profile"}`}</ListItem>
                        </Link>
                        <Link
                          to="#"
                          onClick={() => {
                            signingOut();
                          }}
                          style={{ textDecoration: "none", color: "black" }}
                        >
                          <ListItem>{`${userDetails?.languagejson?.header
                            ?.Logout || "Logout"}`}</ListItem>
                        </Link>
                      </List>
                    </div>
                  }
                  {...defaultTippyProps}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <p className={`user-nav__user-name ${matches && "hide"}`}>
                      {spin ? (
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "3rem", width: "10rem" }}
                        />
                      ) : (
                        `${user?.first_name} ${user?.last_name} `
                      )}
                    </p>
                    <Button
                      className={classes.accountButton}
                      title="User-Profile"
                    >
                      {spin ? (
                        <AccountCircle
                          style={{ color: "white" }}
                          className={classes.small}
                        />
                      ) : (
                        <Avatar
                          className={classes.small}
                          src={`https://${
                            config.DOMAIN
                          }/${config.aws_org_id.toLowerCase()}-resources/images/profile-images/${
                            user?.avtid
                          }.png`}
                        />
                      )}
                    </Button>
                  </div>
                </Tippy>
              </div>
            </nav>
          </div>
        </Container>
      </header>
    </>
  );
}
export default Header;
