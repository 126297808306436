export default function icicivalidate(values) {
    let errors = {};
  
    console.log("Username:", values.username);
    console.log("Type of username:", typeof values.username);

    if (!values.username) {
      errors.email = "Employee ID is required.";
    } else if (!/^[0-9]+$/.test(values.username)) {
      errors.email = "Employee ID must contain only numbers.";
    } else if (values.username.length !== 7) {
      errors.email = "Employee ID must be exactly 7 digits.";
    } else if (values.username.startsWith("0")) {
      errors.email = "Employee ID cannot start with zero.";
    }
    return errors;
  }
  