import React, { useEffect, useState } from "react";

import NavigateNextIcon from "@material-ui/icons/NavigateNext";

import { makeStyles, Breadcrumbs, Button } from "@material-ui/core";
import BookmarkRoundedIcon from "@material-ui/icons/BookmarkRounded";
import { useSelector, useDispatch } from "react-redux";
import { authData, awsSignIn } from "../../redux/auth/authSlice";
import { Link, useHistory } from "react-router-dom";
import { Skeleton } from "@material-ui/lab";
import StarIcon from "@material-ui/icons/Star";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import CustomizedDialogs from "../../components/Dialogs/Dialogs";
import config from "../../aws-exports";
import API from "@aws-amplify/api";
import { ReactComponent as LPIcon } from "../../assets/svg/LP_icon.svg";
import { ReactComponent as CourseIcon } from "../../assets/svg/Courses.svg";
import { ReactComponent as TotalTimeIcon } from "../../assets/svg/Total time.svg";
import { ReactComponent as ContentsIcon } from "../../assets/svg/Contents.svg";
import LearningPathSnake from "../../components/LearningPath/LearningPath";
import { get_jfwfeedbackreporsonlpid } from "../../api/api";
import Swal from "sweetalert2";

function LearningPath() {
  const [color, setColors] = useState("");
  const [imgError, setImgError] = useState(false);

  const handleImageError = () => {
    setImgError(true);
  };

  useEffect(() => {
    const themeColor = localStorage.getItem("themecolor");
    setColors(themeColor.replace("!important", ""));
  }, []);

  let userDetailsL = useSelector(authData);
  const useBookMarkStyles = makeStyles({
    outline: {
      fontSize: "3rem",
      fill: "none",
      strokeWidth: "1px",
      strokeLinejoin: "round",
      stroke: userDetailsL.themeDark === "dark" ? "white" : "#646464",
    },
    filled: {
      fontSize: "3rem",
      fill: "#FFDA46",
      stroke: "#FFDA46",
      strokeWidth: "1px",
      strokeLinejoin: "round",
    },
  });

  const useStyles = makeStyles((theme) => ({
    responsive: {
      [theme.breakpoints.up(1300)]: {
        fontSize: "1rem",
      },
    },
    mainContainer: {
      display: "flex",
      alignItems: "center",
      justifyContent: "start",
      "@media (max-width: 550px)": {
        marginBottom: "1rem",
      },
    },
    ratingButton: {
      fontSize: "1.4rem",
      color: "#01498e",
      backgroundColor: "#fff!important",
      border: "2px solid #01498e",
      fontWeight: "600",
      textTransform: "none",
      "@media (max-width: 550px)": {
        width: "auto",
      },
    },
    bookmarkIcon: {
      cursor: "pointer",
      marginLeft: "1.5rem",
      "@media (max-width: 550px)": {
        marginLeft: "0.5rem",
      },
    },
    loaderSkeleton: {
      width: "200px",
      height: "3rem",
      "@media (max-width: 750px)": {
        width: "200px",
      },
    },
    points: {
      backgroundColor: "#FFDA46",
      width: "3rem",
      height: "3rem",
      borderRadius: "3rem",
      fontWeight: "600",
      fontSize: "1.2rem",
      alignItems: "center",
      display: "flex",
      justifyContent: "center",
      color: "black",
      margin: "0px 8px",
    },
    pointsHours: {
      width: "50%",
      "@media (max-width: 550px)": {
        width: "100%",
      },
    },
    pointsHoursInner: {
      display: "flex",
      margin: "1rem 0",
      alignItems: "center",
      "@media (max-width: 550px)": {
        justifyContent: "space-between",
      },
    },
    rootBody: {
      padding: "2rem",
      backgroundColor:
        userDetailsL.themeDark === "dark" ? "#0073B9" : "#EAF7FF",
      borderRadius: "5px",
      boxShadow:
        userDetailsL.themeDark === "dark" ? "none" : "0px 0px 20px 0px #c3d0dc",
      display: "flex",
      gap: "2rem",
      marginTop: "2rem",
      "@media (max-width: 1020px)": {
        display: "grid",
      },
    },
    leftBody: {
      width: "30%",
      "@media (max-width: 1020px)": {
        width: "100%",
      },
    },
    leftBodyBox: {
      backgroundColor: userDetailsL.themeDark === "dark" ? "#3F434D" : "#fff",
      borderRadius: "6px",
      overflow: "hidden",
      marginTop: "2rem",
      marginRight: "6rem",
      boxShadow:
        userDetailsL.themeDark === "dark" ? "none" : "0px 0px 2px 0px #e6e6e6",
      border: userDetailsL.themeDark === "dark" ? "none" : "1px solid #D4DFE7",
      height: "max-content",
      "@media (max-width: 1020px)": {
        margin: "2rem auto 0rem",
      },
      "& > div > p": {
        margin: "2rem 0rem",
        color: userDetailsL.themeDark === "dark" ? "#fff" : "gray",
      },
    },
    leftBodyImage: {
      height: "18rem",
      backgroundColor: color,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    rightBody: {
      width: "70%",
      "@media (max-width: 1020px)": {
        width: "100%",
      },
    },
    flexClassM: {
      borderTop:
        userDetailsL.themeDark === "dark"
          ? "1px solid #C7C7C7"
          : "1px solid #87898C",
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      padding: "1rem 0rem",
    },
    flexClass: {
      display: "flex",
      gap: "2rem",
      alignItems: "center",
    },
  }));
  const classes = useStyles();
  const classBookmark = useBookMarkStyles();
  const userDetails = useSelector(authData);
  const [isBookmarkedLoaded, setIsBookmarkedLoaded] = useState(false);
  const [isLoading, setIsLoading] = React.useState(true);
  const [sharedObject, setSharedObject] = React.useState({});
  const [rateValue, setRateValue] = useState(1);
  const [open, setOpen] = useState(false);
  const [btnLoad, setBtnLoad] = useState(false);
  const [courseCount, setCourseCount] = useState(0);
  const [contentCount, setContentCount] = useState(0);
  const [externalCount, setExternalCount] = useState(0);
  const [jfwcount,setJfwcount] = useState(0)

  const [time, setTime] = useState("");
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    if (userDetails === 0) {
      window.location.href = config.aws_cloudfront_lurl;
    } else {
      window.scrollTo(0, 0);
      getlearningContent();

      return () => {};
    }
  }, []);
  const handleRateClick = () => {
    setOpen(true);
  };

  const getlearningContent = async () => {
    try {
      const requestBody = {
        ur_id: userDetails?.uData?.ur_id,
        schema: config.schema,
        lpid: userDetails?.learning?.lpid,
      };
      const jsonString = JSON.stringify(requestBody);
      const base64EncodedString = btoa(jsonString);
      const reportInfo = `/getLearningContent?json=${encodeURIComponent(
        base64EncodedString
      )}`;
      const response = await API.get(
        config.aws_cloud_logic_custom_name,
        reportInfo,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: userDetails?.accessToken,
          },
        }
      );

      if (response && response.statusCode === 200) {
        response.body.lname = userDetails?.learning?.lname;
        setSharedObject(response.body);
        
        let coursecount = 0;
        let contentCount = 0;
        let external = 0;
        let totalTime = 0;
        response.body.res.forEach((item) => {
          if (item.type === 1) {
            coursecount = coursecount + 1;
          } else if (item.type === 2) {
            contentCount = contentCount + 1;
          } else if (item.type === 3) {
            if(item.id === '1'){
              external = !item.jfwcount ? 0 : item.jfwcount
            }else{
              external = external + 1;
          } 
            }
            
        });

        response.body.res.forEach((item) => {
          if (item.idtls.dur) {
            totalTime = totalTime + item.idtls.dur;
          }
        });
        let timer = `${Math.floor(totalTime / 3600)}h ${Math.floor(
          (totalTime % 3600) / 60
        )}m ${Math.floor((totalTime % 3600) % 60)}s`;
        setTime(timer);
        setContentCount(contentCount);
        setCourseCount(coursecount);
        setExternalCount(external);
        setIsLoading(false);
        setOpen(false);
      } else {
        response.body.lname = userDetails?.learning?.lname;
        response.body.res = [];
        setSharedObject(response.body);
        setIsLoading(false);
        setOpen(false);
      }
    } catch (err) {
      setIsLoading(false);
      console.error(err);
    }
  };
  const addAndRemoveBookmark = async (val) => {
    setIsBookmarkedLoaded(true);
    const bdata1 = {
      ur_id: userDetails?.uData?.ur_id,
      schema: config.schema,
      lpid: userDetails?.learning?.lpid,
    };

    if (val === 0) {
      bdata1.bookmark = true;
      bdata1.bookmark_date = 1;
    } else {
      bdata1.bookmark = false;
    }
    const jsonString = JSON.stringify(bdata1);
    const base64EncodedString = btoa(jsonString);

    const bodyParam = {
      body: {
        json: base64EncodedString,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken,
      },
    };

    try {
      await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateContentReport",
        bodyParam
      );
      sharedObject.bookmark = bodyParam.body.bookmark;
      setSharedObject(sharedObject);
      setIsBookmarkedLoaded(false);
    } catch (err) {
      console.error(err);
    }
  };
  const handleitemClick = async(dtls, id, type, lpid,jfwcount) => {
    const breadcrumbs = { ...userDetails };
    breadcrumbs.location = {
      from: breadcrumbs.location.from,
      topicName: breadcrumbs.location.topicName,
      topicId: breadcrumbs.location.topicId,
      lpid: lpid,
      lname: userDetails?.learning?.lname,
    };
    if (type === 1) {
      breadcrumbs.courseName = dtls?.title;
      dispatch(awsSignIn(breadcrumbs));
      history.push(`/course?id=${id}`);
    } else if (type === 2) {
      breadcrumbs.contentName = dtls?.title;
      dispatch(awsSignIn(breadcrumbs));
      history.push(`/content?id=${id}`);
    } else if (type === 3) {
      if(id === '1'){
        breadcrumbs.contentName = dtls?.title;
        dispatch(awsSignIn(breadcrumbs));
        let get_jfwfeedbackrepors = await get_jfwfeedbackreporsonlpid(config.schema,userDetails?.uData?.ur_id,userDetails,lpid);
        
        
        if(get_jfwfeedbackrepors.length < jfwcount){
          history.push(`/learning/forms/${lpid}`);
        }else {
           Swal.fire({
            title: "Sorry!",
            text: "You  submitted already",
            icon: "error",
            confirmButtonText: "OK"
                    })
        }
       
        
      }else{
        window.open(id, "_blank"); // Opens the link stored in `id` in a new tab
      }
     
    }
  };
  const syncObjectsData = async () => {
    setBtnLoad(true);
    const bodydt = {
      ur_id: userDetails?.uData?.ur_id,
      schema: config.schema,
      lpid: userDetails?.learning?.lpid,
    };
    bodydt.rating = rateValue;
    bodydt.rating_date = 1;
    const jsonString = JSON.stringify(bodydt);
    const base64EncodedString = btoa(jsonString);
    const bodyParam = {
      body: {
        json: base64EncodedString,
      },
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: userDetails?.accessToken,
      },
    };
    try {
      const response = await API.post(
        config.aws_cloud_logic_custom_name,
        "/updateRateAndBookMark",
        bodyParam
      );
      if (response) {
        setBtnLoad(false);
        setOpen(false);
        getlearningContent();
      }
    } catch (err) {
      setBtnLoad(false);
      console.error(err);
    }
  };

  return (
    <>
      {userDetails !== 0 &&
        userDetails !== undefined &&
        userDetails.location !== undefined && (
          <>
            {userDetails.location.from !== undefined &&
            (userDetails.location.from === "Home" ||
              userDetails.location.from === "Bookmarks" ||
              userDetails.location.from === "Explore" ||
              userDetails.location.from === "ViewAll" ||
              userDetails.location.from === "Search") ? (
              <Breadcrumbs
                separator={
                  <NavigateNextIcon
                    fontSize="large"
                    style={{ fill: "var(--color-primary)" }}
                  />
                }
                aria-label="breadcrumb"
                style={{ marginTop: "3rem", marginBottom: "3rem" }}
              >
                {userDetails.location.from === "ViewAll" && [
                  <Link
                    key="home-link"
                    color="inherit"
                    to="/home"
                    style={{
                      color: "#1974b5",
                      fontSize: "1.5rem",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    Home
                  </Link>,

                  <Link
                    key="view-all-link"
                    color="inherit"
                    to={
                      userDetails.location.from === "ViewAll"
                        ? "/viewallpath"
                        : "#"
                    }
                    style={{
                      color: `${
                        userDetails.location.from === "ViewAll"
                          ? "#1974b5"
                          : "#777"
                      }`,
                      fontSize: "1.5rem",
                      textDecoration: "none",
                      cursor: `${
                        userDetails.location.from === "ViewAll"
                          ? "pointer"
                          : "context-menu"
                      }`,
                    }}
                  >
                    {userDetails.location.from}
                  </Link>,
                ]}

                {userDetails.location.from == "Home" ? (
                  <Link
                    color="inherit"
                    to={userDetails.location.from === "Home" ? "/home" : "#"}
                    style={{
                      color: `${
                        userDetails.location.from === "Home"
                          ? "#1974b5"
                          : "#777"
                      }`,
                      fontSize: "1.5rem",
                      textDecoration: "none",
                      cursor: `${
                        userDetails.location.from === "Home"
                          ? "pointer"
                          : "context-menu"
                      }`,
                    }}
                  >
                    {userDetails.location.from}
                  </Link>
                ) : (
                  ""
                )}

                {userDetails.location.from == "Explore" ? (
                  <Link
                    color="inherit"
                    to={userDetails.location.from === "Explore" ? "/home" : "#"}
                    style={{
                      color: "#777",
                      fontSize: "1.5rem",
                      textDecoration: "none",
                      cursor: "context-menu",
                    }}
                  >
                    {userDetails.location.from}
                  </Link>
                ) : (
                  ""
                )}

                {userDetails.location.from == "Bookmarks" ? (
                  <Link
                    color="inherit"
                    to={
                      userDetails.location.from === "Bookmarks"
                        ? "/bookmarks"
                        : "#"
                    }
                    style={{
                      color: `${
                        userDetails.location.from === "Bookmarks"
                          ? "#1974b5"
                          : "#777"
                      }`,
                      fontSize: "1.5rem",
                      textDecoration: "none",
                      cursor: `${
                        userDetails.location.from === "Bookmarks"
                          ? "pointer"
                          : "context-menu"
                      }`,
                    }}
                  >
                    {userDetails.location.from}
                  </Link>
                ) : (
                  ""
                )}
                {userDetails.location.topicName && (
                  <Link
                    to={
                      userDetails.location.courseName
                        ? `/explore/${userDetails.location.topicName.replaceAll(
                            /\s/g,
                            "-"
                          )}/${userDetails.location.topicId}`
                        : "#"
                    }
                    style={{
                      color: `${
                        userDetails.location.courseName ? "#1974b5" : "#777"
                      }`,
                      fontSize: "1.5rem",
                      textDecoration: "none",
                      cursor: `${
                        userDetails.location.courseName
                          ? "pointer"
                          : "context-menu"
                      }`,
                    }}
                    color="inherit"
                  >
                    {userDetails.location.topicName}
                  </Link>
                )}
                {userDetails.location.lname && (
                  <Link
                    to={
                      userDetails.location.lname
                        ? `/explore/${userDetails.location.lname.replaceAll(
                            /\s/g,
                            "-"
                          )}/${userDetails.location.lpid}`
                        : "#"
                    }
                    style={{
                      color: `${
                        userDetails.location.contentName ? "#1974b5" : "#777"
                      }`,
                      fontSize: "1.5rem",
                      textDecoration: "none",
                      cursor: `${
                        userDetails.location.contentName
                          ? "pointer"
                          : "context-menu"
                      }`,
                    }}
                    color="inherit"
                  >
                    {userDetails.location.lname}
                  </Link>
                )}

                {userDetails.location.viewAll && (
                  <Link
                    to={
                      userDetails.location.viewAllRoute
                        ? userDetails.location.viewAllRoute
                        : "/ExploreCourseView"
                    }
                    color="inherit"
                    style={{
                      color: "#1974b5",
                      fontSize: "1.5rem",
                      textDecoration: "none",
                      cursor: "pointer",
                    }}
                  >
                    {`${userDetails?.languagejson?.Home_Page?.View_all ||
                      "View all"}`}
                  </Link>
                )}
              </Breadcrumbs>
            ) : (
              ""
            )}
          </>
        )}
      {open && (
        <CustomizedDialogs
          open={open}
          setOpen={setOpen}
          value={rateValue}
          setValue={setRateValue}
          syncObjectsData={syncObjectsData}
          btnLoad={btnLoad}
          setBtnLoad={setBtnLoad}
        />
      )}
      <div className="flex-between">
        <div className={classes.mainContainer}>
          <h3 style={{ fontWeight: "600" }} className="responsive">
            {isLoading ? (
              <Skeleton className={classes.loaderSkeleton} />
            ) : (
              <>{sharedObject.lname}</>
            )}
          </h3>

          {isLoading ? (
            <Skeleton
              style={{ width: "60px", height: "5rem", margin: "10px" }}
            />
          ) : (
            <>
              <span className="ml vLine"></span>
              <StarIcon className="icon icon-star" />
              <span style={{ margin: "4px" }}>
                {sharedObject?.star?.toFixed(1) || "0.0"}
              </span>
            </>
          )}
        </div>

        <diV className={classes.pointsHours}>
          {isLoading ? (
            <Skeleton
              style={{ width: "60px", height: "5rem", margin: "10px" }}
            />
          ) : (
            <>
              <div className={classes.pointsHoursInner}>
                <div style={{ display: "flex", alignItems: "center" }}>
                  <AccessTimeIcon className="icon" />
                  <span style={{ margin: "4px" }}>{time}</span>
                </div>
                <div
                  style={{
                    display: "none",
                    alignItems: "center",
                    marginLeft: "10%",
                  }}
                >
                  <div className={classes.points}>450</div>
                  <div>{`${userDetails?.languagejson?.Learning_path?.points ||
                    "Points"}`}</div>
                </div>
              </div>
            </>
          )}
        </diV>
        <div className="flex-container">
          {isLoading ? (
            <Skeleton className={classes.loaderSkeleton} />
          ) : (
            <>
              {(!sharedObject.rate || sharedObject?.rate === null) && (
                <Button
                  variant="outlined"
                  className={classes.ratingButton}
                  onClick={() => {
                    handleRateClick();
                    setRateValue(1);
                  }}
                >
                  {`${userDetails?.languagejson?.Learning_path?.Rate_content ||
                    "Rate content"}`}
                </Button>
              )}
            </>
          )}

          {isLoading || isBookmarkedLoaded ? (
            <Skeleton
              style={{ width: "80px", height: "5rem", margin: "10px" }}
            />
          ) : (
            <>
              {" "}
              {sharedObject?.bookmark ? (
                <div
                  className={classes.bookmarkIcon}
                  onClick={() => {
                    setIsBookmarkedLoaded(true);

                    addAndRemoveBookmark(1);
                  }}
                >
                  <BookmarkRoundedIcon className={classBookmark.filled} />
                </div>
              ) : (
                <div
                  style={{ cursor: "pointer", marginLeft: "1.5rem" }}
                  onClick={() => {
                    setIsBookmarkedLoaded(true);
                    addAndRemoveBookmark(0);
                  }}
                >
                  <BookmarkRoundedIcon className={classBookmark.outline} />
                </div>
              )}{" "}
            </>
          )}
        </div>
      </div>

      {/* Learning Path Section */}
      {isLoading ? (
        <Skeleton className={classes.loaderSkeleton} />
      ) : (
        <div className={classes.rootBody}>
          <div className={classes.leftBody}>
            <h3>{`${userDetails?.languagejson?.Learning_path?.Summary ||
              "Summary"}`}</h3>
            <div className={classes.leftBodyBox}>
              <div className={classes.leftBodyImage}>
                {!imgError ? (
                  <img
                    src={`https://${
                      config.DOMAIN
                    }/${config.aws_org_id.toLowerCase()}-resources/images/learningpath-images/${
                      userDetails?.learning?.lpid
                    }.png`}
                    alt="Learning Path Image"
                    style={{ width: "100%", height: "100%" }}
                    onError={handleImageError}
                  />
                ) : (
                  <LPIcon />
                )}

                {/*   <LPIcon /> */}
              </div>
              <div style={{ padding: "1rem 2rem" }}>
                <div className={classes.flexClassM} style={{ border: "none" }}>
                  <div className={classes.flexClass}>
                    <CourseIcon style={{ width: "28px", height: "28px" }} />
                    <p>{`${userDetails?.languagejson?.Learning_path?.Courses ||
                      "Courses"}`}</p>
                  </div>
                  <p>{courseCount}</p>
                </div>
                <div className={classes.flexClassM}>
                  <div className={classes.flexClass}>
                    <ContentsIcon style={{ width: "28px", height: "28px" }} />
                    <p>{`${userDetails?.languagejson?.Learning_path?.Contents ||
                      "Contents"}`}</p>
                  </div>
                  <p>{contentCount}</p>
                </div>
                {config.aws_org_id === "ICICI" && (
                  <div className={classes.flexClassM}>
                    <div className={classes.flexClass}>
                      <ContentsIcon style={{ width: "28px", height: "28px" }} />
                      <p>External Resource</p>
                    </div>
                    <p>{externalCount}</p>
                  </div>
                )}
                <div className={classes.flexClassM}>
                  <div className={classes.flexClass}>
                    <TotalTimeIcon style={{ width: "28px", height: "28px" }} />
                    <p>{`${userDetails?.languagejson?.Learning_path
                      ?.Total_time || "Total time"}`}</p>
                  </div>
                  <p>{time}</p>
                </div>
              </div>
            </div>
          </div>
          <div className={classes.rightBody}>
            <LearningPathSnake
              isLoading={isLoading}
              sharedObject={sharedObject}
              handleitemClick={handleitemClick}
              color={color}
             
              
            />
          </div>
        </div>
      )}
    </>
  );
}
export default LearningPath;
